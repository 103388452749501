import {useState} from "react";
import {Button, Stack, TextField} from "@mui/material";
import {api_mpoRequestToBeesLambda} from "../store/api_mpo";
import {useAtomValue, useSetAtom} from "jotai/index";
import {feedbackSnackbarAtom, userInfoAtom} from "../store/atom";

function MpoRequest() {
    const [requestUrl, setRequestUrl] = useState("");
    const [intend, setIntend] = useState("");
    const [loading, setLoading] = useState(false);
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const userInfo = useAtomValue(userInfoAtom);

    const handleRequest = () => {
        setLoading(true);
        api_mpoRequestToBeesLambda(userInfo?.email, requestUrl, intend).then(() => {
            console.log('mpo_request success');
            setFeedbackSnackbar(['MPO 요청이 성공적으로 등록되었습니다. 잠시 후 슬랙 알림 보낼게요.', 'success']);
            setLoading(false);
            setRequestUrl("");
            setIntend("");
        }).catch((e) => {
            console.log('mpo request Error', e);
            setFeedbackSnackbar([`MPO 요청 중 문제가 발생했습니다: ${e.message}`, 'error']);
            setLoading(false);
        });
    }

    const canRequest = userInfo?.email && requestUrl && !loading

    return (
        <Stack spacing={3}>
            <TextField label="MPO 요청 URL" value={requestUrl} onChange={(e) => setRequestUrl(e.target.value)}></TextField>
            <TextField multiline minRows={5} label="MPO 요청 의도"
                       placeholder="MPO에 특별히 부탁할 일이 있을 때 사용합니다 (선택사항)"
                       value={intend} onChange={(e) => setIntend(e.target.value)}></TextField>
            <Button onClick={handleRequest} disabled={! canRequest}>요청</Button>
        </Stack>
    )
}

export default MpoRequest;